@use "@angular/material" as mat;
@use "app/core/theming/palette" as scc;
@use "app/core/theming/refunds-theme" as refunds;
@import "variables/colors";

@include mat.core();

$scc-primary: mat.define-palette(scc.$dark-blue-palette, 900, 200, 900, 300);
$scc-accent: mat.define-palette(mat.$amber-palette, 600, 400, 800);
// The warn palette is optional (defaults to red).
$scc-warn: mat.define-palette(mat.$red-palette);
$my-custom-typography-config: mat.define-typography-config(
  $headline-5:
    mat.define-typography-level(112px, 112px, 300, $letter-spacing: -0.05em),
  $headline-6:
    mat.define-typography-level(56px, 56px, 400, $letter-spacing: -0.02em),
  $subtitle-1:
    mat.define-typography-level(45px, 48px, 400, $letter-spacing: -0.005em),
);
$scc-theme: mat.define-light-theme(
  (
    color: (
      primary: $scc-primary,
      accent: $scc-accent,
      warn: $scc-warn,
    ),
    typography: mat.define-typography-config(),
    density: 0,
  )
);

@include mat.all-component-themes($scc-theme);
@include refunds.theme($scc-theme);

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  color: rgba(0, 0, 0, 0.87);
}

* {
  box-sizing: border-box;
  min-height: 0;

  scrollbar-color: lighten($main, 15%) transparent;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 4px;
    height: 0;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: lighten($main, 15%);
  }
}

.fi {
  &.fis {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin: 0 8px;
  }
}

h1 {
  color: $main;
  font-weight: bold;
  letter-spacing: 1px;
  font-size: 16px;
  padding: 16px 8px;
  margin: 0;
}

h2 {
  color: lighten($main, 10%);
  letter-spacing: 1px;
  font-size: 14px;
  padding: 8px;
  margin: 0;
}

h3 {
  letter-spacing: 1px;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  text-transform: uppercase;
  padding: 8px 0;
}

div.mat-mdc-select-panel {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  width: 100%;
  max-height: 275px;
  outline: 0;
  overflow: auto;
  padding: 8px 0;
  border-radius: 4px;
  box-sizing: border-box;
  position: static;
  // background-color: var(--mat-select-panel-background-color);
  background-color: white;
}

.badge {
  margin: 8px;
  height: 24px;
  width: 24px;
  line-height: 24px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  cursor: pointer;
}

.td-inside {
  display: flex;
  align-items: center;
}

.mat-mdc-raised-button.create-remittance {
  height: 42px;
  .mdc-button__label {
    display: flex;
    gap: 8px;
    align-items: center;
  }
}
.alert {
  color: red;
}
.loading-shade {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.pointer {
  cursor: pointer;
}

@use 'sass:map';
@use '@angular/material' as mat;


@mixin color($theme) {
  $color-config: mat.get-color-config($theme);

  $primary-palette: map.get($color-config, 'primary');

  .side-nav-drawer{
    background: mat.get-color-from-palette($primary-palette);
  }
  app-refunds {
    .mdc-list-item__primary-text{
      color: mat.get-color-from-palette($primary-palette, 300);
  
    }
    .mdc-list-item.mdc-list-item--with-one-line {    
      &.active {
        .mdc-list-item__primary-text{
          color: mat.get-color-from-palette($primary-palette);    
        }
          background: mat.get-color-from-palette($primary-palette, 300);
      }
    }
  }
  .action-buttons .mat-mdc-icon-button{
      color: mat.get-color-from-palette($primary-palette)
  }
  .badge{
    border: 2px solid mat.get-color-from-palette($primary-palette);
    color: mat.get-color-from-palette($primary-palette);
    background: white;
  }
  app-remittances-refunds {
    .button-amount .mdc-button__label{
      color: rgba(0, 0, 0, 0.87);
    }
    .mat-button-toggle-checked{
      background-color: mat.get-color-from-palette($primary-palette, 300);
      .mat-button-toggle-label-content{
        color: white
      }
    }
  }
  h3 {
    color: mat.get-color-from-palette($primary-palette, 300);;
  }
  app-remittances-table {
    .type-icon .mat-icon{
      color: #5f6368
    }
  }
}
@mixin theme($theme) {
    $color-config: mat.get-color-config($theme);
    @if $color-config != null {
      @include color($theme);
    }
  }

